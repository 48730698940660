<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Categorias de Produtos</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openAddProductCategoryModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="categories"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column label="criado em">
        <template #default="cat"
          >{{ new Date(cat.row.created_at).toLocaleDateString() }}
        </template>
      </el-table-column>
      <el-table-column prop="products.length" label="produtos cadastrados">
      </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openAddProductCategoryModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteCategory(cat.row)"
                title="
              Remover categoria?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <product-category-modal
      :showModal="showProductCategoryModal"
      :category="category"
      @close-modal="showProductCategoryModal = false"
      @should-update="fetchProductCategories"
    ></product-category-modal>
  </el-card>
</template>

<script>
import ProductCategoryModal from "./modals/ProductCategory.vue";
import { ElNotification } from "element-plus";
export default {
  name: "ProductCategory",
  components: { ProductCategoryModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    categories: null,
    category: null,
    showProductCategoryModal: false,
  }),
  mounted() {
    //this.fetchUserGroups();
    this.fetchProductCategories();
  },
  methods: {
    openAddProductCategoryModal(e) {
      (this.category = e), (this.showProductCategoryModal = true);
    },
    deleteCategory(c) {
      fetch(`${this.$store.state.apiUrl}categories/${c.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.fetchProductCategories();
            ElNotification.success({
              title: `Categoria removida com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao remover a categoria",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    fetchProductCategories() {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.categories = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>