<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Identificação:</h4>
      </el-row>
      <base-input
        label="Nome:"
        hint="Identificação da categoria"
        v-model="newCategory.name"
      >
      </base-input>
      <el-row type="flex" align="center" justify="space-between">
        <h4>Forma de Armazenamento:</h4>
        <el-button
          type="primary"
          iconOnly
          @click="addProperties('storage')"
          icon="el-icon-circle-plus-outline"
        ></el-button>
      </el-row>
      <el-row
        v-for="i in FilterSettingsByType('storage')"
        :key="i.uid"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input label="Nome:" hint="congelado" v-model="i.name">
          </base-input>
        </el-col>
        <el-button
          type="danger"
          iconOnly
          @click="removeProperties(i)"
          icon="el-icon-remove-outline"
        ></el-button>
      </el-row>

      <el-row type="flex" align="center" justify="space-between">
        <h4>Forma de Corte:</h4>
        <el-button
          type="primary"
          iconOnly
          @click="addProperties('cutting')"
          icon="el-icon-circle-plus-outline"
        ></el-button>
      </el-row>
      <el-row
        v-for="i in FilterSettingsByType('cutting')"
        :key="i.uid"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input label="Nome:" hint="em cubos" v-model="i.name">
          </base-input>
        </el-col>
        <el-button
          type="danger"
          iconOnly
          @click="removeProperties(i)"
          icon="el-icon-remove-outline"
        ></el-button>
      </el-row>

      <el-row type="flex" align="center" justify="space-between">
        <h4>Composição dos Produtos:</h4>
        <el-button
          type="primary"
          iconOnly
          @click="addProperties('composition')"
          icon="el-icon-circle-plus-outline"
        ></el-button>
      </el-row>
      <el-row
        v-for="i in FilterSettingsByType('composition')"
        :key="i.uid"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input label="Nome:" hint="com osso" v-model="i.name">
          </base-input>
        </el-col>
        <el-button
          type="danger"
          iconOnly
          @click="removeProperties(i)"
          icon="el-icon-remove-outline"
        ></el-button>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import { uuid } from "vue-uuid";

export default {
  props: ["category", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      newCategory: this.category || {},
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    category(v) {
      this.newCategory = v || {};
    },
  },
  computed: {
    ModalTitle() {
      return "uid" in (this.newCategory || {})
        ? "Editar Categoria de Produtos"
        : "Cadastrar Categoria de Produtos";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  created() {
    //this.fetchSystemActions();
  },
  methods: {
    FilterSettingsByType(type) {
      return Array.isArray(this.newCategory.properties)
        ? this.newCategory.properties.filter((e) => e.type === `${type}`)
        : [];
    },
    removeProperties(r) {
      if (Array.isArray(this.newCategory.properties)) {
        this.newCategory.properties = this.newCategory.properties.filter(
          (e) => e.uid != r.uid
        );
      }
    },
    addProperties(s) {
      if (!Array.isArray(this.newCategory.properties))
        this.newCategory.properties = [];

      this.newCategory.properties.push({
        type: s,
        name: "",
        uid: uuid.v4(),
      });
    },
    save() {
      this.isLoadingSave = true;

      return this.category ? this.updateCategory() : this.createCategory();
    },
    createCategory() {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          ...this.newCategory,
          properties: this.newCategory.properties || [],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Categoria cadastrada com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao criar a categoria",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    updateCategory() {
      fetch(`${this.$store.state.apiUrl}categories/${this.newCategory.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(this.newCategory),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Categoria alterada com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao alterar a categoria",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    async fetchSystemActions() {
      fetch(`${this.$store.state.apiUrl}system/actions`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          throw response.json();
        })
        .then((json) => (this.systemActions = json))
        .catch(() => (this.systemActions = []));
    },
  },
  name: "ProductCategoryModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>